import Api from '@/api'
import store from '@/store'
import router from '@/router'

const LOGIN = '/auth/token/'
const RESET_PASSWORD = '/password_reset/'
const RESET_PASSWORD_CONFIRM = '/password_reset/confirm/?token=:token'
const SIGNUP = '/users/'
const DAMIA_USERS = '/users/damia/'
const ME = '/me/'

export default {
  login (data) {
    return Api.http(false).post(LOGIN, data)
  },
  resetPassword (data) {
    return Api.http(false).post(RESET_PASSWORD, data)
  },
  confirmPassword (data) {
    return Api.http(false).post(RESET_PASSWORD_CONFIRM.replace(':token', data.token), data)
  },
  logout () {
    store.commit('portal/logout')
    router.push('/login')
  },
  signUp (data) {
    return Api.http(false).post(SIGNUP, data)
  },
  getUsers () {
    return Api.http(true).get(SIGNUP)
  },
  getDamiaUsers () {
    return Api.http(true).get(DAMIA_USERS)
  },
  me () {
    return Api.http(true).get(ME)
  }
}